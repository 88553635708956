<script>
export default {
  components: {},
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  },
  props: {
    cart: {
      default: "",
    },
  },
};
</script>

<template>
  <div id="footer-store">
    <div v-if="this.$route.path != '/store/cart'" id="" class="cart-resume d-flex align-items-center p-3 d-block d-sm-none">
      <div>
        <img style="height:30px;" src="@/assets/images/icon-cart.svg" />
      </div>
      <div class="cart-total flex-fill ml-2">
        Meu Carrinho<br>
        <strong>{{ cart.total | currency }} – {{ cart.items.total }} produto(s)</strong>
      </div>
      <div class="cart-checkout">
        <router-link tag="a" to="/store/cart" class="btn btn-light btn-sm bg-white">
          FINALIZAR
        </router-link>
      </div>
    </div>
    <p class="mt-5 mb-4 font-size-12 text-uppercase text-muted text-center">
      <a class="text-muted font-size-11" target="_blank" href="https://m2n.com.br/?utm_source=bionatpro.com.br&utm_medium=link&utm_campaign=logo">
        <small>TECNOLOGIA POR</small> <img alt="M2N" style="width:40px;" src="@/assets/images/logo-m2n.svg" />
      </a>
    </p>
  </div>
</template>
