<script>
import { api } from "@/state/services";
export default {
  components: {},
  data() {
    return {
      franchise: {
        modal: false,
        list: null,
        receive: 1,
        withdraw: "",

        chosen: null,
      },

      company: "",

      store: {
        progress: [],
        type: {
          id: null,
          name: null,
          discount: null,
        },
        types: null,
        choose: {
          type: null,
          modal: false,
        },
      },

      search: {
        loading: false,
        value: this.$route.query.q,
      },
    };
  },
  methods: {
    getConfig() {
      api.get("store/config").then((response) => {
        if (response.data.status == "success") {
          if (response.data.franchise.status == "unselected") {
            this.franchise.modal = true;
          } else {
            this.franchise.chosen = response.data.franchise;
          }

          this.store.type = response.data.store.type;
          (this.store.progress = response.data.store.progress),
            (this.store.types = response.data.store.types);
          this.store.choose.type = this.store.type.id;
        }
      });
    },
    getFranchises() {
      api.get("store/franchises").then((response) => {
        if (response.data.status == "success") {
          this.franchise.list = response.data.list;
        }
      });
    },
    setReceive() {
      api
        .post("store/franchises", {
          id: this.franchise.receive,
          type: "receive",
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.franchise.modal = false;
            localStorage.removeItem("cart");
            this.$parent.getCart();
            this.getConfig();
            this.$parent.$parent.getProducts();
          }
        });
    },
    getCompany() {
      api.get("store/company").then((response) => {
        if (response.data.status == "success") {
          this.company = response.data.company;
        }
      });
    },
    setWithdrawCompany() {
      api
        .post("store/franchises", {
          id: this.franchise.receive,
          type: "withdraw",
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.franchise.modal = false;
            localStorage.removeItem("cart");
            this.$parent.getCart();
            this.getConfig();
            this.$parent.$parent.getProducts();
          }
        });
    },
    setWithdrawFranchise() {
      api
        .post("store/franchises", {
          id: this.franchise.withdraw,
          type: "withdraw",
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.franchise.modal = false;
            localStorage.removeItem("cart");
            this.$parent.getCart();
            this.getConfig();
            this.$parent.$parent.getProducts();
          }
        });
    },
    setStoreChange() {
      api
        .post("store/change", {
          type: this.store.choose.type,
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.store.choose.modal = false;
            localStorage.removeItem("cart");
            this.$parent.getCart();
            this.getConfig();
            this.$parent.$parent.getProducts();
          }
        });
    },
    getPercent(from, to) {
      var total = this.total;
      if (total) {
        total = total.replace("R$ ", "");
        total = total.replace(",", ".");
        if (total - from < 0) {
          return 0;
        }
        if (((total - from) * 100) / (to - from) <= 100) {
          return (((total - from) * 100) / (to - from)).toFixed(2);
        } else {
          return 100;
        }
      } else {
        return 0;
      }
    },
    searchProduct() {
      if (this.search.value) {
        this.$router.push({ path: '/store/search', query: { q: this.search.value } })
      } else {
        this.$router.push('/store/featured');
      }
    }
  },
  mounted() {
    this.getConfig();
    this.getCompany();
    this.getFranchises();
  },
  props: {
    total: {
      default: "",
    },
  },
};
</script>

<template>
  <div class="mb-4">
    <div v-if="franchise.chosen" class="card mb-1">
      <div class="card-body py-3">
        <div class="d-flex align-items-center justify-content-between">
          <div style="line-height: 18px">
            <template v-if="franchise.chosen.type == 'receive'">
              Você escolheu<br /><b>Receber em Casa</b>
            </template>
            <template v-else>
              Você escolheu retirar em<br /><b>{{ franchise.chosen.name }}</b>
            </template>
          </div>
          <button class="btn btn-default btn-sm float-right m-1 text-uppercase" @click="franchise.modal = true">
            Alterar
          </button>
        </div>
      </div>
    </div>

    <b-modal v-model="franchise.modal" size="xl" no-close-on-backdrop hide-header hide-footer centered>
      <router-link tag="a" :to="'/'" class="text-dark"><i class="bx bx-arrow-back"></i> Ir para o Escritório
        Virtual</router-link>
      <hr />
      <div class="row mt-5 justify-content-center">
        <div class="col-lg-4">
          <div class="bg-soft-light rounded p-3 text-center mb-4">
            <img class="mb-3" width="80" src="@/assets/images/store-shipping.png" />
            <h4>Receber em Casa</h4>
            <p>Escolha uma opção de frete para receber os seus produtos.</p>
            <button class="btn btn-default btn-lg" v-on:click="setReceive()">
              Selecionar
            </button>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="bg-soft-light rounded p-3 text-center mb-4">
            <img class="mb-3" width="80" src="@/assets/images/store-company.png" />
            <h4>Retirar na Sede</h4>
            <div v-if="company" class="mb-3 border bg-white rounded p-4 text-left">
              <h6 class="mb-0 d-flex">
                <i class="bx bxs-map font-size-20 align-middle"></i>
                <div class="ml-1">
                  {{ company }}
                </div>
              </h6>
            </div>
            <button class="btn btn-default btn-lg" v-on:click="setWithdrawCompany()">
              Selecionar
            </button>
          </div>
        </div>
        <div class="d-none col-lg-4" v-if="franchise.list">
          <div class="bg-soft-light rounded p-3 text-center">
            <img class="mb-3" width="80" src="@/assets/images/store-home.png" />
            <h4>Retirar em um PDV</h4>
            <p>Escolha um PDV para efetuar a retirada dos seus produtos.</p>
            <form @submit.prevent="setWithdrawFranchise">
              <select v-model="franchise.withdraw" class="form-control mb-3" id="exampleFormControlSelect1">
                <option value="">Selecione</option>
                <option v-for="fra in franchise.list" :value="fra.id" :key="fra.id">
                  {{ fra.name }}
                </option>
              </select>
              <div v-if="franchise.withdraw" class="mb-3 border bg-white rounded p-4 text-left">
                <h6 class="mb-0">
                  <i class="bx bx-user-pin font-size-20 align-middle"></i>
                  {{ franchise.list[franchise.withdraw].responsible }}
                </h6>
                <h6 class="mb-0" v-if="franchise.list[franchise.withdraw].phone">
                  <i class="bx bx-microphone font-size-20 align-middle"></i>
                  {{ franchise.list[franchise.withdraw].phone }}
                </h6>
                <h6 class="mb-0" v-if="franchise.list[franchise.withdraw].email">
                  <i class="bx bx-mail-send font-size-20 align-middle"></i>
                  {{ franchise.list[franchise.withdraw].email }}
                </h6>
                <h6 class="mt-3 mb-0 d-flex">
                  <i class="bx bxs-map font-size-20 align-middle"></i>
                  <div class="ml-1">
                    {{ franchise.list[franchise.withdraw].company.address }},
                    {{ franchise.list[franchise.withdraw].company.number
                    }}<template>,
                      {{
                        franchise.list[franchise.withdraw].company.complement
                      }}</template><br />
                    {{ franchise.list[franchise.withdraw].company.district }},
                    {{ franchise.list[franchise.withdraw].company.city }}/{{
                      franchise.list[franchise.withdraw].company.state
                    }},
                    {{ franchise.list[franchise.withdraw].company.zipcode }}
                  </div>
                </h6>
              </div>
              <button class="btn btn-default btn-lg" :disabled="!franchise.withdraw">
                Selecionar
              </button>
            </form>
          </div>
        </div>
      </div>
    </b-modal>

    <div v-if="store.type.id" class="card mb-1">
      <div class="card-body py-2">
        <div class="d-flex align-items-center justify-content-between">
          <p class="text-left m-0">
            Você está na loja de <b>{{ store.type.name }}</b>
          </p>
          <button class="btn btn-default btn-sm float-right m-1 text-uppercase" @click="store.choose.modal = true">
            Alterar
          </button>
        </div>
      </div>
    </div>

    <b-modal v-model="store.choose.modal" title="Lojas" hide-footer centered>
      <div class="form-group">
        <form @submit.prevent="setStoreChange">
          <label for="exampleFormControlSelect1">Selecione a loja</label>
          <select v-model="store.choose.type" class="form-control" id="exampleFormControlSelect1">
            <option v-for="(type, index) in store.types" :value="type.id" :key="index">
              {{ type.name }}
            </option>
          </select>
          <button class="btn btn-default text-uppercase mt-2" type="submit">
            Alterar
          </button>
        </form>
      </div>
    </b-modal>

    <div v-if="store.progress && store.progress.length > 0" class="card mb-0">
      <div class="card-body py-3">
        <div class="d-flex">
          <div class="flex-fill" v-for="(row, index) in store.progress" :key="index">
            <div class="progress" style="height: 25px">
              <div clas="progress-bar progress-bar-animated" :class="row.color" role="progressbar" :style="'height: 25px; width:' +
                getPercent(row.value.from, row.value.to) +
                '%'
                " :aria-valuenow="getPercent(row.value.from, row.value.to)" :aria-valuemin="0" :aria-valuemax="100"></div>
            </div>
            <div class="d-flex justify-content-between font-size-12 pt-1">
              <div class="px-1">{{ row.name }}</div>
              <div class="px-1">
                {{ getPercent(row.value.from, row.value.to) }}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="store.type.id" class="card mt-1 mb-0">
      <div class="card-body py-3">
        <b-form @submit.prevent="searchProduct">
          <b-form-group class="m-0">
            <b-input-group>
              <b-form-input id="name" v-model="search.value" type="text"></b-form-input>
              <b-input-group-append>
                <b-button :disabled="search.loading == true" type="submit" variant="default" class="btn-block">
                  Buscar
                  <b-spinner v-if="search.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-form>
      </div>
    </div>
  </div>
</template>
